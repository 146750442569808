


























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { loadView } from "@/utils/helpers";
import SideBarDrawer from "@/views/SideBarDrawer.vue";
import { Getter, namespace } from "vuex-class";
import { ISideBar, IUser } from "@/types/types";
import { links } from "@/data/sidebar";

const authModule = namespace("auth");
@Component({
  components: {
    SideBarDrawer,
    "app--bar": loadView(`NavBar`),
    "app--drawer": loadView(`SideBar`)
  }
})
export default class Master extends Vue {
  @Getter("getNavigationDrawerState") drawerState!: boolean;
  @authModule.Getter("getUserType") userType!: string;
  @authModule.Getter("getUserDetails") user!: IUser;
  state: boolean = true;
  links!: Array<ISideBar | { label: string }>;
  menu: boolean = false;

  emitDrawer(value: boolean) {
    this.state = value;
  }

  closeDrawer(state: boolean) {
    this.$store.dispatch("navigationDrawer", state, { root: true });
  }

  signOut() {
    this.$store.dispatch("auth/logOut", null);
  }

  sidebarLinks(key: string): Array<ISideBar | { label: string }> {
    return links[key];
  }

  @Watch("user")
  onUserChange(user: any) {
    // This is an example script - don't forget to change it!
    // LogRocket.identify("THE_USER_ID_IN_YOUR_APP", {
    //   name: "James Morrison",
    //   email: "jamesmorrison@example.com",
    //   // Add your own custom user variables here, ie:
    //   subscriptionType: "pro"
    // });
  }

  created(): void {
    this.links = this.sidebarLinks("customer");
    this.$store.dispatch("auth/me");
  }
}
