import { ISideBar } from "@/types/types";

export const links: { [key: string]: Array<ISideBar | { label: string }> } = {
  customer: [
    {
      label: "General"
    },
    {
      link: "dashboard",
      icon: "widgets",
      text: "Home"
    },
    {
      link: "orders",
      icon: "shopping_cart",
      text: "Orders"
    },
    {
      link: "transactions",
      icon: "all_inbox",
      text: "Transactions"
    },
    {
      label: "Tracking"
    },
    {
      link: "deliveries.completed",
      icon: "fact_check",
      text: "Completed Orders"
    },
    {
      link: "feedbacks",
      icon: "comment",
      text: "Feedback"
    }
    // {
    //   label: 'Reports',
    // },
    // {
    //   link: 'reports',
    //   icon: 'file_download',
    //   text: 'Exports & Downloads',
    // },
  ]
};
